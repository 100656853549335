export const OPTIMUS = {
  APIURL: 'https://protectedscout.kpfui.dev/api/v2/',
  PROJECTBUCKET: 'kpfui-scout-project-bucket',
  PASSWORDPROTECTEDPROJECTBUCKET:
    'kpfui-scout-password-protected-project-bucket',
  COMPUTEPROJECTBUCKET: 'kpfui-scout-project-compute',
  RHINOAPI: 'https://rhino.kpfui.dev/api'
}

// http://localhost:3000/api/v2/
// 'https://protectedscout.kpfui.dev/api/v2/',
